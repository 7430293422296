import styled from "@emotion/styled";
import theme from "../../styles/theme";

type GridItemProps = {
  columnNum: number;
};

type GridProps = {
  numTestimonials: number;
};

export const AnimationDuration = 200;

export const TestimonialsBase = styled.div`
  background-color: ${theme.colors.darkBlues[5]};
  padding: 60px 8px;

  ${theme.largerThan.small} {
    padding: 80px 0;
  }
`;

export const Content = styled.div`
  max-width: 1400px;
  margin: auto;
`;

export const Title = styled.h2`
  text-align: center;
  margin: 0 40px 40px;
`;

export const AllTestimonials = styled.div<GridProps>`
  display: grid;
  grid-template-columns: auto repeat(${(props) => props.numTestimonials}, 1fr);
  position: relative;
`;

export const TestimonialWrapper = styled.div<GridItemProps>`
  justify-self: center;
  grid-row: 1;
  grid-column: ${(props) => props.columnNum};
  max-width: 352px;
  transition: opacity ${AnimationDuration}ms ease-in-out;
  overflow: hidden;
  margin: 0 -8px;

  ${theme.largerThan.small} {
    margin: initial;
  }
`;

export const TestimonialTransitionStyles = {
  entering: {
    opacity: 0,
    width: "initial",
    height: "initial",
    zIndex: 0,
  },
  entered: {
    opacity: 1,
    width: "initial",
    height: "initial",
    zIndex: 0,
  },
  exiting: {
    opacity: 0,
    width: "initial",
    height: "initial",
    zIndex: 0,
  },
  exited: {
    opacity: 0,
    width: "0px",
    height: "0px",
    zIndex: -1,
  },
  unmounted: {
    opacity: 0,
    width: "0px",
    height: "0px",
    zIndex: -1,
  },
};

export const Arrow = styled.div<GridItemProps>`
  grid-column: ${(props) => props.columnNum};
  grid-row: 1;
  width: 32px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(9, 44, 71, 0.25);
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  align-self: flex-start;
  color: ${theme.colors.darkBlues[75]};
  font-size: 13px;
  line-height: 32px;
  margin: 24px 8px;
  z-index: 1;

  ${theme.largerThan.small} {
    margin: 24px 32px;
    font-size: 16px;
    line-height: 36px;
    width: 36px;
  }
`;
