import styled from "@emotion/styled";
import theme from "../../styles/theme";

type TestimonialCompanyLogoProps = {
  componentWidth: number;
};

export const TestimonialComponent = styled.div`
  max-width: 792px;
`;

export const TestimonialBox = styled.div`
  margin: 0 16px;
  border-radius: 5px;
  background-color: #fafcff;
  display: inline-block;
`;

export const TestimonialText = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  ${theme.smallerThan.small} {
    padding: 24px;
  }
`;

export const TestimonialQuote = styled.div`
  color: ${theme.colors.darkBlues[100]};
  font-size: 1rem;
  line-height: 1.3125rem;
  flex-basis: 100%;
`;

export const TestimonialImageAndPerson = styled.div`
  display: flex;
  flex-direction: row;
  margin: 48px 0 0 0;
  align-items: center;
  flex: 1 1 auto;
`;

export const TestimonialPersonText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.darkBlues[100]};
  font-size: 0.625rem;
  line-height: 1rem;
  flex: 1 1 auto;
`;

export const TestimonialName = styled.div`
  font-weight: 600;
`;

export const TestimonialTitle = styled.div``;

export const TestimonalPersonImage = styled.img`
  margin: 0 12px 0 0;
  border-radius: 50%;
  width: 56px;
`;

export const TestimonialCompanyLogo = styled.img<TestimonialCompanyLogoProps>`
  max-height: 60px;
  max-width: 100%;
  align-self: flex-start;

  ${(props) => {
    if (props.componentWidth < 500)
      return `
        order: -1;
        margin: 0 0 28px 0;
      `;
    else
      return `
        margin: 48px 0 0 0;
      `;
  }}
`;
