import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const CenteredFullWidthCTABase = styled.div`
  padding: 72px 12%;
  text-align: center;
  background: ${theme.colors.darkBlues[5]};

  ${theme.largerThan.large} {
    padding: 80px 20%;
  }
`;

export const Headline = styled.h2`
  font-size: 21px;
  line-height: 27px;
  margin-top: 0;
  margin-bottom: 24px;

  ${theme.largerThan.small} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 26px;
  }
`;

export const NewsletterFormWrapper = styled.div`
  max-width: 432px;
  margin: 0 auto;
`;
