import React from "react";
import {
  BenefitQuadrantBase,
  Headline,
  Subheadline,
  Footer,
  Benefits,
  Benefit,
  BenefitCopy,
  Image,
  Title,
  Description,
} from "./benefit-quadrant.styled";
import { BenefitQuadrantStandardLandingPageFragment } from "../../types/graphql";
import { htmlSerializer } from "../../utils/htmlSerializer";
import { RichText } from "prismic-reactjs";

interface IBenefitQuadrantProps {
  benefitQuadrant: BenefitQuadrantStandardLandingPageFragment;
}

const BenefitQuadrant = (props: IBenefitQuadrantProps) => {
  const { benefitQuadrant } = props;

  return (
    (benefitQuadrant && (
      <BenefitQuadrantBase>
        {benefitQuadrant.primary?.headline && (
          <Headline>{benefitQuadrant.primary?.headline}</Headline>
        )}
        {benefitQuadrant.primary?.subheadline && (
          <Subheadline>
            <RichText
              render={benefitQuadrant.primary?.subheadline}
              htmlSerializer={htmlSerializer}
            />
          </Subheadline>
        )}
        {benefitQuadrant.fields && (
          <Benefits>
            {benefitQuadrant.fields.slice(0, 4).map((benefit, index) => {
              return (
                <Benefit key={index}>
                  {benefit.image && (
                    <Image
                      src={benefit.image.url}
                      alt={benefit.image.alt || "Xometry Image"}
                    />
                  )}
                  <BenefitCopy>
                    {benefit.title && <Title>{benefit.title}</Title>}
                    {benefit.description && (
                      <Description>
                        <RichText
                          render={benefit.description}
                          htmlSerializer={htmlSerializer}
                        />
                      </Description>
                    )}
                  </BenefitCopy>
                </Benefit>
              );
            })}
          </Benefits>
        )}
        {benefitQuadrant.primary?.footer && (
          <Footer>
            <RichText
              render={benefitQuadrant.primary.footer}
              htmlSerializer={htmlSerializer}
            />
          </Footer>
        )}
      </BenefitQuadrantBase>
    )) || <></>
  );
};

export default BenefitQuadrant;
