import { graphql } from "gatsby";

export const homeTestimonialsQuery = graphql`
  fragment TestimonialsHome on PRISMIC_HomeBody1Testimonials {
    primary {
      title
    }
    type
    fields {
      company_image
      person_image
      person_name
      person_title
      quote
    }
  }
`;

export const standardLandingPageQuery = graphql`
  fragment TestimonialStandardLandingPage on PRISMIC_Standard_landing_pageBodyTestimonial {
    primary {
      company_image
      person_image
      person_name
      person_title
      quote
    }
    type
  }
`;

export const standardLandingPageTestimonialsQuery = graphql`
  fragment TestimonialsStandardLandingPage on PRISMIC_Standard_landing_pageBodyTestimonials {
    primary {
      title
    }
    type
    fields {
      company_image
      person_image
      person_name
      person_title
      quote
    }
  }
`;
