import { graphql } from "gatsby";

export const FeaturedCustomersHomeQuery = graphql`
  fragment FeaturedCustomersHome on PRISMIC_HomeBody1Featured_customers {
    type
    label
    primary {
      subtitle
    }
    fields {
      company_logo
      company_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
    }
  }
`;

export const FeaturedCustomersStandardLandingPageQuery = graphql`
  fragment FeaturedCustomersStandardLandingPage on PRISMIC_Standard_landing_pageBodyFeatured_customers {
    type
    label
    primary {
      subtitle
    }
    fields {
      company_logo
      company_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
    }
  }
`;
