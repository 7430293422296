import styled from "@emotion/styled";
import theme from "../../styles/theme";
import { default as CTAComponent } from "../cta/cta";

type HeroProps = {
  imageMobile: string;
  imageDesktop: string;
};

export const HeroBackground = styled.div<HeroProps>`
  background-color: #f5faff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.imageDesktop});
  padding: 69px 16px 162px 9%;

  @media (max-width: 656px) {
    padding: 24px 6% 62px;
    background-image: linear-gradient(
        180deg,
        rgba(37, 56, 66, 0) 80%,
        #253842 106%
      ),
      url(${(props) => props.imageMobile});
  }
`;

export const Title = styled.h1`
  max-width: 540px;
  font-weight: 600;
  color: #0a304e;
  flex: 0 1 auto;
`;

export const Subtitle = styled.h2`
  max-width: 540px;
  color: ${theme.colors.darkBlues[90]};
  flex: 1 0 auto;
  font-size: 1rem;
  line-height: 1.3125rem;
`;

export const CTAWrapper = styled.div`
  margin: 24px 0;
  @media (max-width: 656px) {
    width: 100%;
    text-align: center;
  }
`;

export const CTAButton = styled(CTAComponent)<{}>`
  max-width: 400px;

  @media (max-width: 656px) {
    width: 100%;
  }
`;
