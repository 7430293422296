import { graphql } from "gatsby";

export const BenefitQuadrantHomeQuery = graphql`
  fragment BenefitQuadrantHome on PRISMIC_HomeBody1Benefit_quadrant {
    type
    label
    primary {
      headline
      subheadline
      footer
    }
    fields {
      title
      description
      image
    }
  }
`;

export const BenefitQuadrantStandardLandingPageQuery = graphql`
  fragment BenefitQuadrantStandardLandingPage on PRISMIC_Standard_landing_pageBodyBenefit_quadrant {
    type
    label
    primary {
      headline
      subheadline
      footer
    }
    fields {
      title
      description
      image
    }
  }
`;
