import styled from "@emotion/styled";
import { Spinner as LoftSpinner, theme } from "@xometry/xometry_loft";

export const YoutubeBase = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const YoutubeIFrame = styled.iframe<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: all 1.5s;
  opacity: ${(props) => (props.show ? "1" : "0")};
`;

export const ThumbnailContainer = styled.div<{ hide: boolean }>`
  width: 100%;
  height: 100%;
  background: ${theme.colors.darkBlues[25]};
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  z-index: ${(props) => (props.hide ? "0" : "100")};
`;

export const ThumbnailImage = styled.img`
  display: block;
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Spinner = styled(LoftSpinner)`
  margin: initial !important;
  width: initial !important;
  position: absolute !important;
  top: calc(50% - 35px);
  left: 50%;
  z-index: 1000;
`;

export const PlayerButton = styled.svg`
  width: 68px;
  height: 48px;
  position: absolute;
  margin: 0 auto;
  top: calc(50% - 24px);
  left: calc(50% - 34px);

  path:first-of-type {
    fill: #212121;
    fill-opacity: 0.8;
  }

  .thumbnail-container:hover & {
    path:first-of-type {
      fill: #f00;
      fill-opacity: 1;
    }
  }
`;
