import { graphql } from "gatsby";

export const MediaMarqueeHomeQuery = graphql`
  fragment MediaMarqueeHome on PRISMIC_HomeBody1Media_marquee {
    type
    fields {
      feature_title
      feature_description
      feature_image
    }
    primary {
      headline
      mediaMarqueeDescription: description
      video_url
      footer
    }
  }
`;

export const MediaMarqueeStandardLandingPageQuery = graphql`
  fragment MediaMarqueeStandardLandingPage on PRISMIC_Standard_landing_pageBodyMedia_marquee {
    type
    fields {
      feature_title
      feature_description
      feature_image
    }
    primary {
      headline
      mediaMarqueeDescription: description
      video_url
      footer
    }
  }
`;
