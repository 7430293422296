import React from "react";
import {
  CenteredFullWidthCTABase,
  Headline,
  NewsletterFormWrapper,
} from "./centered-full-width-cta.styled";
import { CenteredFullWidthCtaStandardLandingPageFragment } from "../../types/graphql";
import CTA from "../cta/cta";
import loadable from "@loadable/component";

// Lazy load newsletter form since Formik and Yup bloat bundle sizes
const NewsletterForm = loadable(
  () => import("../newsletter-form/newsletter-form")
);

interface ICenteredFullWidthCtaProps {
  centeredFullWidthCTA: CenteredFullWidthCtaStandardLandingPageFragment;
}

const CenteredFullWidthCTA = (props: ICenteredFullWidthCtaProps) => {
  const { primary } = props.centeredFullWidthCTA;

  return primary ? (
    <CenteredFullWidthCTABase>
      {primary.headline && <Headline>{primary.headline}</Headline>}
      {primary.cta_action === "Newsletter Signup" ? (
        <NewsletterFormWrapper>
          <NewsletterForm
            ctaKind="primary"
            ctaScale="large"
            eventPageSection="hero-content-hub"
            inputScale="large"
            alignment="center"
          />
        </NewsletterFormWrapper>
      ) : (
        primary.cta_text && (
          <CTA
            kind="primary"
            scale="large"
            pageSection="centered-full-width-cta"
            ctaText={primary.cta_text}
            action={primary.cta_action}
            linkURL={primary.cta_link && primary.cta_link?.url}
            driftBotInteractionID={primary.drift_bot_interaction_id}
          />
        )
      )}
    </CenteredFullWidthCTABase>
  ) : (
    <></>
  );
};

export default CenteredFullWidthCTA;
