import styled from "@emotion/styled";
import theme from "../../styles/theme";
import CTA from "../cta/cta";

export const SimpleInlineCTABase = styled.div`
  max-width: 996px;
  box-sizing: border-box;
  padding: 48px 15%;
  border-radius: 14px;
  background: linear-gradient(180deg, #f5f7f8 0%, #ffffff 100%);
  text-align: center;

  ${theme.largerThan.small} {
    padding: 84px 15% 60px;
  }
`;

export const Headline = styled.h3`
  font-weight: 600;
  margin-bottom: 8px;
`;

export const Subheadline = styled.div`
  color: ${theme.colors.darkBlues[100]};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 36px;

  ${theme.largerThan.small} {
    margin-bottom: 28px;
  }
`;

export const StyledCTA = styled(CTA)`` as typeof CTA;
