import { graphql } from "gatsby";

export const standardLandingPageCenteredFullWidthCtaQuery = graphql`
  fragment CenteredFullWidthCtaStandardLandingPage on PRISMIC_Standard_landing_pageBodyCentered_full_width_cta {
    type
    primary {
      headline
      cta_action
      cta_text
      drift_bot_interaction_id
      cta_link {
        ... on PRISMIC__ExternalLink {
          _linkType
          url
        }
      }
    }
  }
`;

export const homeCenteredFullWidthCtaQuery = graphql`
  fragment CenteredFullWidthCtaHome on PRISMIC_HomeBody1Centered_full_width_cta {
    type
    primary {
      headline
      cta_action
      cta_text
      drift_bot_interaction_id
      cta_link {
        ... on PRISMIC__ExternalLink {
          _linkType
          url
        }
      }
    }
  }
`;
