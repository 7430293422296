import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const MediaMarqueeBase = styled.div`
  background: ${theme.colors.darkBlues[5]};
  padding: 40px 6.75%;

  ${theme.largerThan.large} {
    margin-top: 60px;
    padding: 112px 6.75%;
  }
`;

export const Content = styled.div`
  max-width: ${theme.maxWidth};
  margin: auto;
`;

export const PrimaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  ${theme.largerThan.large} {
    flex-direction: row;
    margin-top: -172px;
    margin-bottom: 36px;
  }
`;

export const PrimaryCopy = styled.div`
  margin-bottom: 36px;

  ${theme.largerThan.medium} {
    margin-right: 5%;
  }

  ${theme.largerThan.large} {
    flex: 2;
    margin-top: 120px;
    margin-bottom: 24px;
  }

  ${theme.largerThan.extraLarge} {
    flex: 1;
  }
`;

export const Headline = styled.h2`
  color: ${theme.colors.darkBlues[100]};
  margin-bottom: 16px;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.darkBlues[90]};
`;

export const VideoEmbed = styled.div`
  flex: 3;
  max-width: 792px;
`;

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  margin-bottom: 36px;

  ${theme.largerThan.small} {
    margin-bottom: 60px;
  }

  ${theme.largerThan.large} {
    flex-direction: row;
    padding: initial;
  }
`;

export const Feature = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: initial;
  }

  ${theme.largerThan.large} {
    margin-bottom: initial;
    margin-right: 10%;

    &:last-of-type {
      margin-right: initial;
    }
  }
`;

export const FeatureImage = styled.img`
  max-width: 78px;
  max-height: 78px;
  object-fit: contain;
`;

export const FeatureTitle = styled.div`
  font-size: 1rem;
  line-height: 1.3125rem;
  color: ${theme.colors.darkBlues[100]};
  font-weight: 600;
`;

export const FeatureDescription = styled.div`
  color: ${theme.colors.darkBlues[90]};
  font-size: 16px;
  line-height: 24px;
  max-width: 380px;
`;

export const Footer = styled.div`
  max-width: 588px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.darkBlues[90]};

  /* Default link color doesn't pass accessibility contrast specs with this background */
  /* https://web.dev/color-contrast/?utm_source=lighthouse&utm_medium=devtools#how-to-ensure-text-has-sufficient-color-contrast */
  a {
    color: ${theme.colors.blues[125]};
  }
`;
