import React from "react";
import {
  FeaturedCustomersBase,
  Subtitle,
  Images,
  Image,
  ImageLink,
} from "./featured-customers.styled";
import { FeaturedCustomersStandardLandingPageFragment } from "../../types/graphql";
import { RichText } from "prismic-reactjs";
import { htmlSerializer } from "../../utils/htmlSerializer";

interface IFeaturedCustomersProps {
  featuredCustomers: FeaturedCustomersStandardLandingPageFragment;
}

const FeaturedCustomers = (props: IFeaturedCustomersProps) => {
  const subtitle = props.featuredCustomers.primary?.subtitle;
  const customers = props.featuredCustomers.fields;

  return (
    <FeaturedCustomersBase>
      {customers && (
        <Images>
          {customers.map((customer, index) => {
            return (
              customer.company_logo && (
                <ImageLink key={index} href={customer.company_link?.url}>
                  <Image
                    src={customer.company_logo.url}
                    alt={customer.company_logo.alt || "Xometry Image"}
                  />
                </ImageLink>
              )
            );
          })}
        </Images>
      )}
      {subtitle && (
        <Subtitle>
          <RichText render={subtitle} htmlSerializer={htmlSerializer} />
        </Subtitle>
      )}
    </FeaturedCustomersBase>
  );
};

export default FeaturedCustomers;
