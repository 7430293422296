import React from "react";
import {
  TextAndImageBase,
  Text,
  Badge,
  Image,
  TextTitle,
  TextBlock,
  BulletPoint,
  BulletPoints,
  Checkmark,
} from "./text-and-image.styled";
import { TextAndImageStandardLandingPageFragment } from "../../types/graphql";
import { RichText } from "prismic-reactjs";
import { htmlSerializer } from "../../utils/htmlSerializer";

interface ITextAndImageProps {
  textAndImage: TextAndImageStandardLandingPageFragment;
}

const TextAndImage = (props: ITextAndImageProps) => {
  var textAndImage = props.textAndImage.primary;
  let bullets = props.textAndImage.fields;
  return textAndImage ? (
    <TextAndImageBase>
      <Text alignment={props.textAndImage.label}>
        {textAndImage.display_new_badge && <Badge>New!</Badge>}
        {textAndImage.title && <TextTitle>{textAndImage.title}</TextTitle>}
        {textAndImage.text && (
          <TextBlock>
            <RichText
              render={textAndImage.text}
              htmlSerializer={htmlSerializer}
            />
          </TextBlock>
        )}
        {bullets && bullets.length > 0 && (
          <BulletPoints className="fa-ul">
            {bullets.map((bullet, index) => {
              return (
                bullet.bullet_point && (
                  <BulletPoint key={index}>
                    <Checkmark className="fa-li far fa-check" />
                    {bullet.bullet_point}
                  </BulletPoint>
                )
              );
            })}
          </BulletPoints>
        )}
      </Text>
      {textAndImage.image && (
        <Image
          alignment={props.textAndImage.label}
          src={textAndImage.image.url}
          alt={textAndImage.image.alt || "Xometry Image"}
        />
      )}
    </TextAndImageBase>
  ) : (
    <></>
  );
};

export default TextAndImage;
