import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const NewsletterSignupBase = styled.div`
  background-color: ${theme.colors.darkBlues[100]};
  padding: 24px 16px;

  ${theme.largerThan.medium} {
    padding: 40px;
  }
`;

export const NewsletterSignupContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${theme.maxWidth};
  margin: 0 auto;

  ${theme.largerThan.medium} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const NewsletterText = styled.div`
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.3125rem;
  font-weight: 600;
  margin-bottom: 16px;
  flex: 1 1 auto;
  text-align: center;

  ${theme.largerThan.medium} {
    font-size: 21px;
    line-height: 28px;
    margin-right: 48px;
    margin-bottom: initial;
  }
`;
