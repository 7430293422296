import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const BenefitQuadrantBase = styled.div`
  max-width: ${theme.maxWidth};
`;

export const Headline = styled.h2`
  max-width: 792px;
  text-align: center;
  color: ${theme.colors.darkBlues[100]};
  margin: 0 auto 12px;
`;

const RichTextCopy = styled.div`
  max-width: 588px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.darkBlues[90]};
`;

export const Subheadline = styled(RichTextCopy)`
  margin: 0 auto 36px;

  ${theme.largerThan.small} {
    margin: 0 auto 60px;
  }
`;

export const Footer = styled(RichTextCopy)`
  margin: 36px auto 0;

  ${theme.largerThan.small} {
    margin-top: 60px;
  }
`;

export const Benefits = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 60px;
  padding: 0 8px;

  ${theme.largerThan.small} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
`;

export const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${theme.largerThan.medium} {
    align-items: center;
    flex-direction: row;
  }
`;

export const BenefitCopy = styled.div``;

export const Image = styled.img`
  max-width: 78px;
  max-height: 78px;
  object-fit: contain;
  margin-bottom: 16px;

  ${theme.largerThan.small} {
    margin-right: 16px;
  }
`;

export const Title = styled.div`
  font-size: 1rem;
  line-height: 1.3125rem;
  color: ${theme.colors.darkBlues[100]};
  font-weight: 600;
`;

export const Description = styled.div`
  color: ${theme.colors.darkBlues[90]};
  font-size: 16px;
  line-height: 24px;
  max-width: 380px;
`;
