import { graphql } from "gatsby";

export const homeNewsletterSignupQuery = graphql`
  fragment NewsletterSignupHome on PRISMIC_HomeBody1Newsletter_signup {
    type
    primary {
      description
      cta_button_text
    }
  }
`;

export const standardLandingPageNewsletterSignupQuery = graphql`
  fragment NewsletterSignupStandardLandingPage on PRISMIC_Standard_landing_pageBodyNewsletter_signup {
    type
    primary {
      description
      cta_button_text
    }
  }
`;
