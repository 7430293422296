import React from "react";
import {
  NewsletterSignupBase,
  NewsletterText,
  NewsletterSignupContent,
} from "./newsletter-signup.styled";
import { NewsletterSignupStandardLandingPageFragment } from "../../types/graphql";
import loadable from "@loadable/component";

// Lazy load newsletter form since Formik and Yup bloat bundle sizes
const NewsletterForm = loadable(
  () => import("../newsletter-form/newsletter-form")
);

interface INewsletterSignupProps {
  newsletter: NewsletterSignupStandardLandingPageFragment;
}

const NewsletterSignup = (props: INewsletterSignupProps) => {
  const newsletter = props.newsletter.primary;

  return (
    <NewsletterSignupBase>
      <NewsletterSignupContent>
        {newsletter?.description && (
          <NewsletterText>{newsletter?.description}</NewsletterText>
        )}
        <NewsletterForm
          ctaKind="primary"
          ctaScale="large"
          eventPageSection="signup"
          inputScale="large"
          alignment="end"
        />
      </NewsletterSignupContent>
    </NewsletterSignupBase>
  );
};

export default NewsletterSignup;
