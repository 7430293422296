import styled from "@emotion/styled";
import theme from "../../styles/theme";

type TextAndImageProps = {
  alignment: string | null | undefined;
};

export const TextAndImageBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: ${theme.maxWidth};

  ${theme.largerThan.large} {
    flex-direction: row;
  }
`;

export const Text = styled.div<TextAndImageProps>`
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  max-width: 492px;
  order: 2;
  min-width: 0;

  ${theme.largerThan.large} {
    order: ${(props) => (props.alignment === "right_align_text" ? 2 : 1)};
  }
`;

export const TextTitle = styled.h3``;

export const Badge = styled.div`
  background: ${theme.colors.purples[10]};
  color: ${theme.colors.purples[100]};
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  padding: 4px 8px;
  width: fit-content;
`;

export const TextBlock = styled.div`
  color: ${theme.colors.darkBlues[90]};
  font-size: 16px;
  line-height: 24px;
`;

export const BulletPoints = styled.ul`
  margin-left: 28px;
`;

export const BulletPoint = styled.li`
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px 0;
  color: #204360;
`;

export const Checkmark = styled.i`
  color: #13bf87;
`;

export const Image = styled.img<TextAndImageProps>`
  order: 1;
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
  flex: 1 1 50%;
  margin: 20px;
  min-width: 0;
  min-height: 0;
  max-height: 275px !important;

  ${theme.largerThan.small} {
    max-width: 492px;
  }

  ${theme.largerThan.large} {
    order: ${(props) => (props.alignment === "right_align_text" ? 1 : 2)};
    max-height: 492px !important;
  }
`;
