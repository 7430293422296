import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const CountUpBase = styled.div`
  background: ${theme.colors.darkBlues[5]};
  padding: 80px 8%;
`;

export const Content = styled.div`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
`;

export const Copy = styled.div`
  text-align: center;
  margin-bottom: 24px;

  ${theme.largerThan.medium} {
    margin-bottom: 36px;
  }
`;

export const Title = styled.h2``;

export const Subtitle = styled.div`
  color: ${theme.colors.darkBlues[75]};
  font-size: 16px;
  line-height: 24px;
`;
export const Categories = styled.div`
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 24px;

  /* On smaller screen sizes have 1 column centered */
  width: fit-content;
  margin: 0 auto;
  grid-template-columns: 1fr;

  /* On larger screen sizes auto-fit the number of columns */
  ${theme.largerThan.large} {
    width: initial;
    margin: initial;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
export const CategoryWrapper = styled.div`
  display: flex;

  ${theme.largerThan.large} {
    justify-content: center;
  }
`;

export const CategoryImage = styled.img`
  max-width: 60px;
  max-height: 60px;
  object-fit: contain;

  margin-right: 16px;
  ${theme.largerThan.small} {
  }
`;

export const CategoryCopy = styled.div``;

export const CategoryTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.darkBlues[75]};
`;

export const Number = styled.span`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: ${theme.colors.darkBlues[100]};

  ${theme.largerThan.medium} {
    font-size: 36px;
    line-height: 44px;
  }
`;
