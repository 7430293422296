import React, { useState } from "react";
import {
  YoutubeBase,
  YoutubeIFrame,
  ThumbnailContainer,
  Spinner,
  ThumbnailImage,
  PlayerButton,
} from "./youtube.styled";

interface IYoutubeProps {
  embedURL: string;
  thumbnailURL: string;
  title: string;
}

// This component optimizes the way we embed Youtube videos on our site
// to boost performance by only loading a thumbnail of the video until
// a interacts with the component (via hovering or clicking). See link
// https://web.dev/third-party-facades/#how-to-defer-third-parties-with-a-facade

const Youtube = ({ embedURL, thumbnailURL, title }: IYoutubeProps) => {
  // Get the videoId from URL. Used to embed video and get thumbnail urls
  const videoId = embedURL.substring(embedURL.lastIndexOf("/") + 1);

  // state for iframe loading. Begins loading once in viewport
  const [iframeLoadStarted, setIframeLoadStarted] = useState(false);

  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [thumbnailClicked, setThumbnailClicked] = useState(false);

  const handleIframeLoad = () => setIframeLoaded(true);
  const startIframeLoad = () => setIframeLoadStarted(true);

  const handleThumbnailClicked = () => {
    setThumbnailClicked(true);
    setIframeLoadStarted(true);
  };

  return (
    (embedURL && thumbnailURL && (
      <YoutubeBase>
        <ThumbnailContainer
          className="thumbnail-container"
          hide={iframeLoaded}
          onClick={handleThumbnailClicked}
          onMouseEnter={startIframeLoad}
          onFocus={startIframeLoad}
          tabIndex={0}
        >
          {/* Show player button until a user clicks on the button */}
          {!thumbnailClicked && !iframeLoaded && (
            <PlayerButton>
              <path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" />
              <path d="M 45,24 27,14 27,34" fill="#ffffff" />
            </PlayerButton>
          )}

          {/* Show spinner if user clicked on thumbnail, but iframe isn't fully loaded */}
          {thumbnailClicked && !iframeLoaded && <Spinner />}

          <ThumbnailImage
            src={thumbnailURL.replace("hq", "maxres")}
            loading="lazy"
            alt={title || "Embedded Youtube Video"}
          />
        </ThumbnailContainer>

        {iframeLoadStarted && (
          <YoutubeIFrame
            // Autoplay if user clicked facade button before iframe loaded
            src={`https://www.youtube.com/embed/${videoId}?autoplay=${
              thumbnailClicked ? "1" : "0"
            }`}
            show={iframeLoaded}
            onLoad={handleIframeLoad}
            title={title || "Embedded Youtube Video"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </YoutubeBase>
    )) ||
    null
  );
};

export default Youtube;
