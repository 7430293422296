import { graphql } from "gatsby";

export const CountUpHomeQuery = graphql`
  fragment CountUpHome on PRISMIC_HomeBody1Count_up {
    type
    primaryFields: primary {
      subtitle
      title
    }
    categories: fields {
      category_image
      category_title
      number
      suffix
    }
  }
`;
