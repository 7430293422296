import React from "react";
import {
  HeroBackground,
  Title,
  Subtitle,
  CTAWrapper,
  CTAButton,
} from "./hero-home.styled";
import { HeroHomeFragment, Prismic__ExternalLink } from "../../types/graphql";
import { RichText } from "prismic-reactjs";
import { htmlSerializer } from "../../utils/htmlSerializer";

interface IHeroHomeProps {
  heroHome: HeroHomeFragment;
}

const HeroHome = (props: IHeroHomeProps) => {
  const heroHome = props.heroHome.primary;

  return (
    (heroHome && (
      <>
        <HeroBackground
          imageMobile={heroHome.image.mobile.url}
          imageDesktop={heroHome.image.url}
        >
          {heroHome.title && <Title>{heroHome.title}</Title>}
          {heroHome.subtitle && (
            <Subtitle>
              <RichText
                render={heroHome.subtitle}
                htmlSerializer={htmlSerializer}
              />
            </Subtitle>
          )}
          <CTAWrapper>
            {heroHome.cta_link && heroHome.cta_text && (
              <CTAButton
                kind="primary"
                scale="xlarge"
                pageSection="home-hero"
                ctaText={heroHome.cta_text}
                linkURL={
                  heroHome.cta_link &&
                  (heroHome.cta_link as Prismic__ExternalLink).url
                }
              />
            )}
          </CTAWrapper>
        </HeroBackground>
      </>
    )) || <></>
  );
};

export default HeroHome;
