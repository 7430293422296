import { graphql } from "gatsby";

export const HeroHomeQuery = graphql`
  fragment HeroHome on PRISMIC_HomeBody1Home_hero {
    type
    primary {
      title
      subtitle
      image
      cta_text
      cta_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
    }
  }
`;
