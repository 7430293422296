import React, { useState } from "react";
import {
  CountUpBase,
  Copy,
  Title,
  Subtitle,
  Categories,
  CategoryWrapper,
  CategoryImage,
  CategoryTitle,
  Number,
  CategoryCopy,
  Content,
} from "./count-up.styled";
import { CountUpHomeFragment } from "../../types/graphql";
import { default as ReactCountUp } from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

interface ICountUpProps {
  countUp: CountUpHomeFragment;
}

const CountUp = (props: ICountUpProps) => {
  const { countUp } = props;
  const { categories } = countUp;
  const [viewed, setViewed] = useState(false);

  const getDecimalPlaces = (number: number) => {
    return number - Math.floor(number) === 0
      ? 0
      : number.toString().split(".")[1].length;
  };

  return (
    (countUp && (
      <CountUpBase>
        <Content>
          {countUp.primaryFields && (
            <Copy>
              {countUp.primaryFields.title && (
                <Title>{countUp.primaryFields?.title}</Title>
              )}
              {countUp.primaryFields?.subtitle && (
                <Subtitle>{countUp.primaryFields.subtitle}</Subtitle>
              )}
            </Copy>
          )}

          {categories && (
            <VisibilitySensor
              onChange={(isViewed) => isViewed && setViewed(true)}
              offset={{ top: 84 }}
              partialVisibility
              minTopValue={100}
            >
              <Categories>
                {categories.map(
                  (category, index) =>
                    category.number && (
                      <CategoryWrapper key={index}>
                        {category.category_image && (
                          <CategoryImage
                            src={category.category_image.url}
                            alt={category.category_image.alt || "Xometry Image"}
                          />
                        )}
                        <CategoryCopy>
                          {category.category_title && (
                            <CategoryTitle>
                              {category.category_title}
                            </CategoryTitle>
                          )}
                          <ReactCountUp
                            start={0}
                            end={viewed ? category.number : 0}
                            decimals={getDecimalPlaces(category.number)}
                            duration={4}
                            suffix={category.suffix || ""}
                            separator=","
                          >
                            {({ countUpRef }) => <Number ref={countUpRef} />}
                          </ReactCountUp>
                        </CategoryCopy>
                      </CategoryWrapper>
                    )
                )}
              </Categories>
            </VisibilitySensor>
          )}
        </Content>
      </CountUpBase>
    )) || <></>
  );
};

export default CountUp;
