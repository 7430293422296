import React, { useState, useRef, useEffect } from "react";
import {
  TestimonialsBase,
  Title,
  TestimonialWrapper,
  Content,
  Arrow,
  AllTestimonials,
  TestimonialTransitionStyles,
  AnimationDuration,
} from "./testimonials.styled";
import { TestimonialsStandardLandingPageFragment } from "../../types/graphql";
import Testimonial from "../../components/testimonial/testimonial";
import { Transition } from "react-transition-group";
import { debounce } from "../../utils/debounce";

interface ITestimonialsProps {
  testimonials: TestimonialsStandardLandingPageFragment;
}

const Testimonials = (props: ITestimonialsProps) => {
  const title = props.testimonials.primary?.title;
  const testimonials = props.testimonials.fields;

  if (!testimonials) return <></>;

  const [testimonalsPerGroup, setTestimonalsPerGroup] = useState(3);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const numGroups = Math.ceil(testimonials.length / testimonalsPerGroup);
  const [currGroupIndex, setCurrGroupIndex] = useState(0);
  const testimonialGroups = [];
  for (let i = 0; i < numGroups; i++) {
    const startIndex = i * testimonalsPerGroup;
    testimonialGroups.push(
      testimonials.slice(startIndex, startIndex + testimonalsPerGroup)
    );
  }

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = testimonialsRef.current?.clientWidth;
      if (!width) return;
      if (width < 800) {
        setTestimonalsPerGroup(1);
      } else if (width < 1200) {
        setTestimonalsPerGroup(2);
      } else {
        setTestimonalsPerGroup(3);
      }
    }, 200);

    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [testimonialsRef]);

  function arrowClicked(direction: number) {
    let newGroupIndex = currGroupIndex + direction;
    if (newGroupIndex < 0)
      // underflow
      newGroupIndex = numGroups - 1;
    else if (newGroupIndex > numGroups - 1)
      // overflow
      newGroupIndex = 0;

    setCurrGroupIndex(newGroupIndex);
  }

  return (
    <TestimonialsBase>
      <Content>
        {title && <Title>{title}</Title>}
        {testimonialGroups.length > 0 && (
          <AllTestimonials
            numTestimonials={testimonalsPerGroup}
            ref={testimonialsRef}
          >
            {testimonialGroups.length > 1 && (
              <Arrow
                columnNum={1}
                className="far fa-arrow-left"
                onClick={() => {
                  arrowClicked(-1);
                }}
              />
            )}
            {testimonialGroups.map((testimonialGroup, groupIndex) => {
              return (
                testimonialGroup && (
                  <Transition
                    in={groupIndex == currGroupIndex}
                    key={groupIndex}
                    timeout={AnimationDuration}
                  >
                    {(state) => {
                      return testimonialGroup.map(
                        (testimonial, testimonialIndex) => {
                          return (
                            <TestimonialWrapper
                              style={{ ...TestimonialTransitionStyles[state] }}
                              key={testimonialIndex}
                              columnNum={testimonialIndex + 2}
                            >
                              <Testimonial testimonial={testimonial} />
                            </TestimonialWrapper>
                          );
                        }
                      );
                    }}
                  </Transition>
                )
              );
            })}
            {testimonialGroups.length > 1 && (
              <Arrow
                columnNum={testimonalsPerGroup + 2}
                className="far fa-arrow-right"
                onClick={() => {
                  arrowClicked(1);
                }}
              />
            )}
          </AllTestimonials>
        )}
      </Content>
    </TestimonialsBase>
  );
};

export default Testimonials;
