import { graphql } from "gatsby";

export const SimpleInlineCTAHomeQuery = graphql`
  fragment SimpleInlineCTAHome on PRISMIC_HomeBody1Simple_inline_cta {
    type
    primary {
      subheadline
      headline
      cta_text
      cta_action
      cta_link {
        ... on PRISMIC__ExternalLink {
          target
          _linkType
          url
        }
      }
      drift_bot_interaction_id
    }
  }
`;
