import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const FullWidthSliceWrapper = styled.div`
  padding: 20px 0;

  ${theme.largerThan.small} {
    padding: 40px 0;
  }
`;

export const NarrowSliceWrapper = styled.div`
  padding: 20px 6.75%;

  ${theme.largerThan.small} {
    padding: 40px 12.5%;
  }

  & > * {
    margin-left: auto;
    margin-right: auto;
  }
`;
