import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const FeaturedCustomersBase = styled.div``;

export const Subtitle = styled.div`
  color: ${theme.colors.darkBlues[75]};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

export const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 4px;
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
  flex: 0 1 auto;
  width: auto;
  height: 49px;
  margin: 0 8px 20px;

  ${theme.largerThan.medium} {
    height: 72px;
  }
`;
