import React, { useRef, useState, useEffect } from "react";
import { TestimonialStandardLandingPageFragment } from "../../types/graphql";
import {
  TestimonialBox,
  TestimonialText,
  TestimonialQuote,
  TestimonialPersonText,
  TestimonialName,
  TestimonialTitle,
  TestimonalPersonImage,
  TestimonialCompanyLogo,
  TestimonialComponent,
  TestimonialImageAndPerson,
} from "./testimonial.styled";
import { debounce } from "../../utils/debounce";

interface ITestimonialProps {
  testimonial: TestimonialStandardLandingPageFragment["primary"];
}

const Testimonial = (props: ITestimonialProps) => {
  const testimonialRef = useRef<HTMLDivElement>(null);
  const [testimonialWidth, setTestimonialWidth] = useState(0);

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = testimonialRef.current?.clientWidth;
      width && setTestimonialWidth(width);
    }, 200);

    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [testimonialWidth]);

  const testimonial = props.testimonial;
  return testimonial ? (
    <TestimonialComponent ref={testimonialRef}>
      <TestimonialBox>
        <TestimonialText>
          {testimonial.quote && (
            <TestimonialQuote>“{testimonial.quote}”</TestimonialQuote>
          )}
          <TestimonialImageAndPerson>
            {testimonial.person_image && (
              <TestimonalPersonImage
                src={testimonial.person_image.url}
                alt={
                  testimonial.person_image.alt || "Testimonial Profile Image"
                }
              />
            )}
            <TestimonialPersonText>
              {testimonial.person_name && (
                <TestimonialName>{testimonial.person_name}</TestimonialName>
              )}
              {testimonial.person_title && (
                <TestimonialTitle>{testimonial.person_title}</TestimonialTitle>
              )}
            </TestimonialPersonText>
          </TestimonialImageAndPerson>
          {testimonial.company_image && (
            <TestimonialCompanyLogo
              src={testimonial.company_image.url}
              alt={testimonial.company_image.alt || "Testimonial Company Logo"}
              componentWidth={testimonialWidth}
            />
          )}
        </TestimonialText>
      </TestimonialBox>
    </TestimonialComponent>
  ) : (
    <></>
  );
};

export default Testimonial;
