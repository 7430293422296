import React from "react";
import {
  HomeQuery,
  HeroHomeFragment,
  CountUpHomeFragment,
  FeaturedCustomersHomeFragment,
  BenefitQuadrantHomeFragment,
  MediaMarqueeHomeFragment,
  TestimonialsHomeFragment,
  TextAndImageHomeFragment,
  SimpleInlineCtaHomeFragment,
  NewsletterSignupHomeFragment,
  CenteredFullWidthCtaHomeFragment,
} from "../../types/graphql";
import Layout from "../../components/layout/layout";
import HeroHome from "../../components/hero-home/hero-home";
import FeaturedCustomers from "../../components/featured-customers/featured-customers";
import Testimonials from "../../components/testimonials/testimonials";
import { HeroHomeQuery } from "../../components/hero-home/hero-home.query";
import { CountUpHomeQuery } from "../../components/count-up/count-up.query";
import { FullWidthSliceWrapper, NarrowSliceWrapper } from "./home.styled";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import CountUp from "../../components/count-up/count-up";
import { FeaturedCustomersHomeQuery } from "../../components/featured-customers/featured-customers.query";
import BenefitQuadrant from "../../components/benefit-quadrant/benefit-quadrant";
import { BenefitQuadrantHomeQuery } from "../../components/benefit-quadrant/benefit-quadrant.query";
import MediaMarquee from "../../components/media-marquee/media-marquee";
import { MediaMarqueeHomeQuery } from "../../components/media-marquee/media-marquee.query";
import { homeTestimonialsQuery } from "../../components/testimonial/testimonial.query";
import { homeQuery as textAndImageQuery } from "../../components/text-and-image/text-and-image.query";
import TextAndImage from "../../components/text-and-image/text-and-image";
import { SimpleInlineCTAHomeQuery } from "../../components/simple-inline-cta/simple-inline-cta.query";
import SimpleInlineCTA from "../../components/simple-inline-cta/simple-inline-cta";
import { homeNewsletterSignupQuery } from "../../components/newsletter-signup/newsletter-signup.query";
import NewsletterSignup from "../../components/newsletter-signup/newsletter-signup";
import { homeCenteredFullWidthCtaQuery as centeredFullWidthCtaQuery } from "../../components/centered-full-width-cta/centered-full-width-cta.query";
import CenteredFullWidthCTA from "../../components/centered-full-width-cta/centered-full-width-cta";

export const query = graphql`
  query Home {
    prismic {
      allHomes {
        edges {
          node {
            body1 {
              ...HeroHome
              ...CountUpHome
              ...FeaturedCustomersHome
              ...BenefitQuadrantHome
              ...MediaMarqueeHome
              ...TestimonialsHome
              ...TextAndImageHome
              ...SimpleInlineCTAHome
              ...NewsletterSignupHome
              ...CenteredFullWidthCtaHome
            }
            body {
              ... on PRISMIC_HomeBodySeo_ {
                primary {
                  meta_description
                  meta_title
                }
              }
            }
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

type HomeProps = {
  data: HomeQuery;
};

const Home = (props: HomeProps) => {
  const home = props.data.prismic.allHomes.edges?.find(() => true)?.node;
  const seoSlice = home?.body && home?.body[0].primary;
  const slices = home?.body1;

  var hero = (slices?.find((slice) => {
    if ("type" in slice && slice.type == "home_hero") {
      return slice;
    }
  }) as HeroHomeFragment)?.primary;

  const seo = {
    meta_title: seoSlice?.meta_title,
    meta_description: seoSlice?.meta_description,
    title: hero?.title,
    description: hero?.subtitle && RichText.asText(hero?.subtitle),
    image: hero?.image.url,
    lang: home?._meta.lang,
  };

  return (
    <Layout seo={seo} isGuttered={false}>
      {slices?.map((slice, index) => {
        if (!("type" in slice)) return;

        if (slice.type == "home_hero") {
          return <HeroHome key={index} heroHome={slice as HeroHomeFragment} />;
        } else if (slice.type == "featured_customers") {
          return (
            <NarrowSliceWrapper key={index}>
              <FeaturedCustomers
                key={index}
                featuredCustomers={slice as FeaturedCustomersHomeFragment}
              />
            </NarrowSliceWrapper>
          );
        } else if (slice.type == "count_up") {
          return (
            <FullWidthSliceWrapper key={index}>
              <CountUp countUp={slice as CountUpHomeFragment} />
            </FullWidthSliceWrapper>
          );
        } else if (slice.type == "benefit_quadrant") {
          return (
            <NarrowSliceWrapper key={index}>
              <BenefitQuadrant
                benefitQuadrant={slice as BenefitQuadrantHomeFragment}
              />
            </NarrowSliceWrapper>
          );
        } else if (slice.type == "media_marquee") {
          return (
            <FullWidthSliceWrapper key={index}>
              <MediaMarquee mediaMarquee={slice as MediaMarqueeHomeFragment} />
            </FullWidthSliceWrapper>
          );
        } else if (slice.type == "testimonials") {
          return (
            <FullWidthSliceWrapper key={index}>
              <Testimonials testimonials={slice as TestimonialsHomeFragment} />
            </FullWidthSliceWrapper>
          );
        } else if (slice.type == "text_and_image") {
          return (
            <NarrowSliceWrapper key={index}>
              <TextAndImage textAndImage={slice as TextAndImageHomeFragment} />
            </NarrowSliceWrapper>
          );
        } else if (slice.type == "simple_inline_cta") {
          return (
            <NarrowSliceWrapper key={index}>
              <SimpleInlineCTA
                simpleInlineCTA={slice as SimpleInlineCtaHomeFragment}
              />
            </NarrowSliceWrapper>
          );
        } else if (slice.type == "newsletter_signup") {
          return (
            <NewsletterSignup
              key={index}
              newsletter={slice as NewsletterSignupHomeFragment}
            />
          );
        } else if (slice.type == "centered_full_width_cta") {
          return (
            <FullWidthSliceWrapper key={index}>
              <CenteredFullWidthCTA
                centeredFullWidthCTA={slice as CenteredFullWidthCtaHomeFragment}
              />
            </FullWidthSliceWrapper>
          );
        }
      })}
    </Layout>
  );
};

Home.fragments = [
  HeroHomeQuery,
  FeaturedCustomersHomeQuery,
  CountUpHomeQuery,
  BenefitQuadrantHomeQuery,
  MediaMarqueeHomeQuery,
  homeTestimonialsQuery,
  textAndImageQuery,
  SimpleInlineCTAHomeQuery,
  homeNewsletterSignupQuery,
  centeredFullWidthCtaQuery,
];

export default Home;
