import React, { useEffect, useState } from "react";
import {
  SimpleInlineCtaHomeFragment,
  Prismic__ExternalLink,
} from "../../types/graphql";
import { htmlSerializer } from "../../utils/htmlSerializer";
import {
  SimpleInlineCTABase,
  Headline,
  Subheadline,
  StyledCTA,
} from "./simple-inline-cta.styled";
import { RichText } from "prismic-reactjs";
import theme from "../../styles/theme";
import { debounce } from "../../utils/debounce";

interface ISimpleInlineCTAProps {
  simpleInlineCTA: SimpleInlineCtaHomeFragment;
}

const SimpleInlineCTA = (props: ISimpleInlineCTAProps) => {
  const simpleInlineCTA = props.simpleInlineCTA.primary;

  const [windowSize, setWindowSize] = useState(1440);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(window.innerWidth);
    }, 200);

    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  return (
    (simpleInlineCTA && (
      <SimpleInlineCTABase>
        {simpleInlineCTA.headline && (
          <Headline>{simpleInlineCTA.headline}</Headline>
        )}
        {simpleInlineCTA.subheadline && (
          <Subheadline>
            <RichText
              render={simpleInlineCTA.subheadline}
              htmlSerializer={htmlSerializer}
            />
          </Subheadline>
        )}
        {(simpleInlineCTA.cta_link ||
          simpleInlineCTA.drift_bot_interaction_id) &&
          simpleInlineCTA.cta_text && (
            <StyledCTA
              kind="primary"
              scale={windowSize > theme.breakpoints[1] ? "xlarge" : "large"}
              pageSection="simple-inline-cta"
              ctaText={simpleInlineCTA.cta_text}
              action={simpleInlineCTA.cta_action}
              linkURL={
                simpleInlineCTA.cta_link &&
                (simpleInlineCTA.cta_link as Prismic__ExternalLink).url
              }
              driftBotInteractionID={simpleInlineCTA.drift_bot_interaction_id}
            />
          )}
      </SimpleInlineCTABase>
    )) || <></>
  );
};

export default SimpleInlineCTA;
