import React from "react";
import {
  MediaMarqueeBase,
  Content,
  Headline,
  Description,
  VideoEmbed,
  PrimaryCopy,
  PrimaryWrapper,
  Features,
  Feature,
  FeatureImage,
  FeatureTitle,
  FeatureDescription,
  Footer,
} from "./media-marquee.styled";
import { MediaMarqueeStandardLandingPageFragment } from "../../types/graphql";
import { RichText } from "prismic-reactjs";
import { htmlSerializer } from "../../utils/htmlSerializer";
import Youtube from "../youtube/youtube";

interface IMediaMarqueeProps {
  mediaMarquee: MediaMarqueeStandardLandingPageFragment;
}

const MediaMarquee = (props: IMediaMarqueeProps) => {
  const { mediaMarquee } = props;
  const features = mediaMarquee.fields;
  const video = mediaMarquee.primary?.video_url;

  return (
    (mediaMarquee && (
      <MediaMarqueeBase>
        <Content>
          <PrimaryWrapper>
            <PrimaryCopy>
              {mediaMarquee.primary?.headline && (
                <Headline>{mediaMarquee.primary?.headline}</Headline>
              )}
              {mediaMarquee.primary?.mediaMarqueeDescription && (
                <Description>
                  <RichText
                    render={mediaMarquee.primary?.mediaMarqueeDescription}
                    htmlSerializer={htmlSerializer}
                  />
                </Description>
              )}
            </PrimaryCopy>
            {video && (
              <VideoEmbed>
                <Youtube
                  embedURL={video.embed_url}
                  thumbnailURL={video.thumbnail_url}
                  title={video.title}
                />
              </VideoEmbed>
            )}
          </PrimaryWrapper>
          {features && (
            <Features>
              {features.slice(0, 3).map(
                (feature, index) =>
                  feature && (
                    <Feature key={index}>
                      {feature.feature_image && (
                        <FeatureImage
                          src={feature.feature_image.url}
                          alt={feature.feature_image.alt || "Xometry Image"}
                        />
                      )}
                      {feature.feature_title && (
                        <FeatureTitle>{feature.feature_title}</FeatureTitle>
                      )}
                      {feature.feature_description && (
                        <FeatureDescription>
                          <RichText
                            render={feature.feature_description}
                            htmlSerializer={htmlSerializer}
                          />
                        </FeatureDescription>
                      )}
                    </Feature>
                  )
              )}
            </Features>
          )}
          {mediaMarquee.primary?.footer && (
            <Footer>
              <RichText
                render={mediaMarquee.primary.footer}
                htmlSerializer={htmlSerializer}
              />
            </Footer>
          )}
        </Content>
      </MediaMarqueeBase>
    )) || <></>
  );
};

export default MediaMarquee;
