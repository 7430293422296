import { graphql } from "gatsby";

export const homeQuery = graphql`
  fragment TextAndImageHome on PRISMIC_HomeBody1Text_and_image {
    type
    label
    primary {
      image
      text
      title
      display_new_badge
    }
    fields {
      bullet_point
    }
  }
`;

export const standardLandingPageQuery = graphql`
  fragment TextAndImageStandardLandingPage on PRISMIC_Standard_landing_pageBodyText_and_image {
    type
    label
    primary {
      image
      text
      title
      display_new_badge
    }
    fields {
      bullet_point
    }
  }
`;
